import React, { useEffect, forwardRef, useImperativeHandle, useRef, useState } from "react";
import css from "./index.module.scss";
import CircleShards from "/public/shards/circle.svg";
import GettrShards from "/public/shards/gettr.svg";
import RocketShards from "/public/shards/rocket.svg";
import EarthShards from "/public/shards/earth.svg";
import BaseShards from "/public/shards/base.svg";
import BoatShards from "/public/shards/boat.svg";
import GearsShards from "/public/shards/gears.svg";
import gsap from "@/scripts/gsap";
import {shuffle} from "@/scripts/utils";
import { CustomEase } from "@/scripts/gsap/CustomEase";

export default forwardRef(({
  className = "",
}, ref) => {
  const [shards, setShards] = useState({
    base: useRef(),
    circle: useRef(),
    outro: useRef(),
    rocket: useRef(),
    earth: useRef(),
    boat: useRef(),
    gears: useRef(),
  });

  function flickerShards(direction){
    gsap.registerPlugin(CustomEase);

    let sorted = Array.from(shards.base.current.querySelectorAll("path"));

    if (direction !== "random"){
      sorted = sorted.sort((a, b) => {
        let aRect = a.getBoundingClientRect();
        let bRect = b.getBoundingClientRect();
        return aRect[direction] - bRect[direction];
      });
    } else if (direction === "random"){
      sorted = shuffle(sorted);
    }

    let tl = gsap.fromTo(sorted, {
      filter: "contrast(100%) invert(0%) grayscale(0%)",
    }, {
      filter: "contrast(150%) invert(20%) grayscale(50%)",
      duration: 0.25,
      ease: CustomEase.create("custom", "M0,0,C0.204,0,0.302,0.8,0.5,0.8,0.7,0.8,0.794,0,1,0"),
      stagger: {
        each: 0.01,
        repeat: -1,
        repeatDelay: 3,
      },
    });
    return tl;
  }

  function endFlicker(){
    gsap.to(Array.from(shards.base.current.querySelectorAll("path")), {
      filter: "brightness(100%) invert(0%) grayscale(0%)",
      duration: 0.25,
      ease: "sine.inOut",
    });
  }

  const [sceneTls, setSceneTls] = useState([]);
  const [scenes, setScenes] = useState({
    base: {},
    circle: {},
    outro: {
      start: () => {
        gsap.registerPlugin(CustomEase);
        let tls = [];
        tls.push(flickerShards("top"));

        setSceneTls(tls);
      },
      stop: (tls) => {
        for (let tl of tls){
          tl.kill();
        }

        setSceneTls([]);
        endFlicker();
      },
    },
    earth: {
      start: () => {
        gsap.registerPlugin(CustomEase);
        let tls = [];
        tls.push(flickerShards("top"));

        setSceneTls(tls);
      },
      stop: (tls) => {
        for (let tl of tls){
          tl.kill();
        }

        setSceneTls([]);
        endFlicker();
      },
    },
    boat: {
      start: () => {
        gsap.registerPlugin(CustomEase);
        let tls = [];
        tls.push(flickerShards("top"));

        //boat motion
        tls.push(gsap.fromTo(shards.base.current, {
          translateX: 0,
        }, {
          translateX: "-5%",
          repeat: -1,
          ease: "sine.inOut",
          yoyo: true,
          duration: 5,
        }));

        setSceneTls(tls);
      },
      stop: (tls) => {
        for (let tl of tls){
          tl.kill();
        }


        //boat moving up and down
        gsap.to(shards.base.current, {
          translateX: 0,
          duration: 0.25,
          ease: "sine.inOut",
        });

        setSceneTls([]);
        endFlicker();
      },
    },
    gears: {
      start: () => {
        gsap.registerPlugin(CustomEase);
        let tls = [];
        tls.push(flickerShards("top"));

        setSceneTls(tls);
      },
      stop: (tls) => {
        for (let tl of tls){
          tl.kill();
        }

        setSceneTls([]);
        endFlicker();
      },
    },
    rocket: {
      start: () => {
        gsap.registerPlugin(CustomEase);
        let tls = [];
        tls.push(flickerShards("top"));

        //flame flickering
        tls.push(gsap.fromTo(shards.base.current.querySelectorAll("path[data-animation-id='flame']"), {
          scaleY: 1,
          transformOrigin: "center top",
          filter: "brightness(100%)",
        }, {
          scaleY: 0.8,
          ease: CustomEase.create("custom", "M0,0,C0,0,0.004,0.014,0.005,0.016,0.012,0.008,0.029,0.032,0.04,0.022,0.04,0.023,0.063,0.062,0.065,0.064,0.077,0.167,0.103,0.273,0.105,0.285,0.106,0.276,0.147,0.037,0.15,0.023,0.155,0.041,0.209,0.235,0.24,0.345,0.262,0.37,0.296,0.409,0.305,0.418,0.306,0.411,0.314,0.379,0.32,0.359,0.352,0.317,0.365,0.022,0.374,0.012,0.374,0.021,0.418,0.307,0.42,0.345,0.437,0.393,0.534,0.212,0.55,0.254,0.565,0.263,0.504,0.498,0.52,0.507,0.545,0.501,0.583,0.492,0.605,0.487,0.63,0.52,0.694,0.177,0.728,0.22,0.739,0.317,0.726,0.56,0.728,0.57,0.728,0.564,0.822,0.179,0.824,0.172,0.825,0.178,0.792,0.557,0.795,0.568,0.797,0.562,0.842,0.653,0.854,0.622,0.863,0.565,0.872,0.491,0.872,0.416,0.875,0.425,0.918,0.427,0.94,0.484,0.977,0.561,1,0.014,1,0.014"),
          filter: "brightness(110%)",
          repeat: -1,
          duration: 0.25,
        }));

        //rocket moving up and down
        tls.push(gsap.fromTo(shards.base.current, {
          translateY: 0,
        }, {
          translateY: "-10%",
          ease: "sine.inOut",
          repeat: -1,
          duration: 10,
          yoyo: true,
        }));


        setSceneTls(tls);
      },
      stop: (tls) => {
        console.log("ROCKET STOP");
        for (let tl of tls){
          tl.kill();
        }

        gsap.to(shards.base.current.querySelectorAll("path[data-animation-id='flame']"), {
          scaleY: 1,
          transformOrigin: "center top",
          filter: "brightness(100%)",
          duration: 0.25,
          ease: "sine.inOut",
        });

        //rocket moving up and down
        gsap.to(shards.base.current, {
          translateY: 0,
          duration: 0.25,
          ease: "sine.inOut",
        });

        setSceneTls([]);
        endFlicker();
      },
    },
  });



  function transitionShards({source, target, flickerDirection, direction = "top", spin = false, speed = 1}){
    let sortedSource = Array.from(source);

    let sortedTarget = Array.from(target);

    if (direction !== "random"){
      sortedSource.sort((a, b) => {
        let aRect = a.getBoundingClientRect();
        let bRect = b.getBoundingClientRect();
        return aRect[direction] - bRect[direction];
      });
      sortedTarget.sort((a, b) => {
        let aRect = a.getBoundingClientRect();
        let bRect = b.getBoundingClientRect();
        return aRect[direction] - bRect[direction];
      });
    } else {
      sortedSource = shuffle(sortedSource);
      sortedTarget = shuffle(sortedTarget);
    }


    let tl = gsap.timeline();
    for (let i = 0; i < sortedSource.length; i++){
      let sourceElement = sortedSource[i];
      let targetElement = sortedTarget[i];

      let allTweens = gsap.getTweensOf(sourceElement, "rotate");
      for (let tween of allTweens){
        if (tween.data === "morph"){
          console.log("KILL");
          tween.kill();
        }
      }

      tl.to(sourceElement, {
        morphSVG: {
          shape: targetElement,
          type: "rotational",
        },
        rotate: 0,
        data: "morph",
        fill: targetElement.getAttribute("fill"),
        ease: "power4.inOut",
        duration: 2 / speed,
        onStart: () => {
          sourceElement.setAttribute("data-animation-id", targetElement.getAttribute("data-animation-id") || "");
          let tweens = gsap.getTweensOf(sourceElement, "rotate");
          for (let tween of tweens){
            if (tween.data === "rotation"){
              tween.kill();
            }
          }
        },
      }, i * 0.01 / speed);

      if (spin){
        tl.fromTo(sourceElement, {
          rotate: 0,
          svgOrigin: "250 250",
        }, {
          rotate: Math.random() > 0.5 ? 360 : -360,
          duration: () => 60 + (Math.random() * 90),
          ease: "linear",
          repeat: -1,
          data: "rotation",
        }, "<25%");
      }
    }


    return tl;
  }


  const background = useRef();
  const [currentShards, setCurrentShards] = useState("circle");


  useImperativeHandle(ref, () => ({
    transition(target){
      endFlicker();
      let options = {
        source: shards.base.current.querySelectorAll("path"),
        target: shards[target].current.querySelectorAll("path"),
        speed: 1,
        spin: false,
        direction: "top",
        flickerDirection: "top",
      };

      if (currentShards === "circle"){
        options.direction = "random";
        gsap.to(background.current, {
          opacity: 0,
          duration: 0.5,
          ease: "sine.inOut",
          delay: 2.5,
        });
      } else if (target === "circle"){
        gsap.to(background.current, {
          opacity: 1,
          duration: 1,
          ease: "sine.inOut",
        });
        options.direction = "random";
        options.speed = 2;
        options.spin = true;
        options.flickerDirection = "random";
      }

      if (target === "outro"){
        options.flickerDirection = "left";
      }

      let tl = gsap.timeline();
      if (scenes[currentShards].stop){
        tl.add(scenes[currentShards].stop(sceneTls), 0);
      }
      tl.add(transitionShards(options), 0);
      if (scenes[target].start){
        tl.add(() => scenes[target].start(), 3);
      }

      setCurrentShards(target);
      return tl;
    },
  }));


  useEffect(() => {
    if (shards.base.current){
      let elements = shards.base.current.querySelectorAll("path");
      for (const shard of elements){
        let animation = gsap.fromTo(shard, {
          rotate: 0,
          svgOrigin: "250 250",
        }, {
          rotate: Math.random() > 0.5 ? 360 : -360,
          duration: () => 60 + (Math.random() * 90),
          ease: "linear",
          repeat: -1,
          data: "rotation",
        }, 0);
      }
    }
  }, [shards]);

  return <div className={`${css.shards} ${className}`}>
    <div className={css.background} ref={background}></div>
    <div ref={shards.base}><BaseShards /></div>
    <div ref={shards.circle}><CircleShards /></div>
    <div ref={shards.outro}><GettrShards /></div>
    <div ref={shards.rocket}><RocketShards /></div>
    <div ref={shards.earth}><EarthShards /></div>
    <div ref={shards.boat}><BoatShards /></div>
    <div ref={shards.gears}><GearsShards /></div>
  </div>;
});



/*

  function changeFlicker(thisShards){
    if (flickerTl){
      flickerTl.kill();
    }

    let sortedShards = Array.from(thisShards).sort((a, b) => {
      let aRect = a.getBoundingClientRect();
      let bRect = b.getBoundingClientRect();
      return aRect.top - bRect.top;
    });

    for (let i = 0; i < sortedShards.length; i++){
      let shard = sortedShards[i];
      let newIndex = Math.min(sortedShards.length, Math.max(0, i + Math.floor((Math.random() * 20) - 10)));
      sortedShards.splice(i, 1);
      sortedShards.splice(newIndex, 0, shard);
    }

    setflickerTl(gsap.fromTo(sortedShards, {
      filter: "brightness(100%) invert(0%)",
    }, {
      filter: "brightness(90%) invert(10%)",
      ease: CustomEase.create("custom", "M0,0,C0.2,0,0.3,1,0.5,1,0.706,1,0.8,0,1,0"),
      duration: 0.4,
      stagger: {
        each: 0.005,
        ease: "sine.inOut",
        repeat: -1,
        repeatDelay: 3,
      },
      delay: 3,
    }));
  }
  */
