import React, { useEffect, forwardRef, useImperativeHandle, useRef, useState } from "react";
import css from "./index.module.scss";
import gsap from "@/scripts/gsap";
import Button from "@/components/button";
import ArrowRightSvg from "/public/icons/arrowRight.svg";
import ArrowLeftSvg from "/public/icons/arrowLeft.svg";
import GrowthGraph from "./growthGraph.svg";
import { DrawSVGPlugin } from "@/scripts/gsap/DrawSVGPlugin";



export default forwardRef(({
  className = "",
  exit = () => {},
}, ref) => {
  const intro = useRef();
  const root = useRef();


  function handleExit(){
    gsap.to([intro.current, root.current], {
      opacity: 0,
      pointerEvents: "none",
      duration: 0.5,
      ease: "sine.inOut",
    });
    exit();
  }

  useEffect(() => {
    gsap.registerPlugin(DrawSVGPlugin);
  }, []);


  useImperativeHandle(ref, () => ({
    show(target){
      let tl = gsap.timeline();
      tl.set(root.current, {visibility: "visible", opacity: 0, pointerEvents: "none"});
      tl.set(intro.current, {visibility: "visible", opacity: 1, pointerEvents: "all"});
      tl.to(root.current, {
        opacity: 1,
        pointerEvents: "all",
        duration: 0.5,
        ease: "sine.inOut",
      }, 0);

      tl.fromTo(intro.current.querySelectorAll(".piece"), {
        opacity: 0,
      }, {
        opacity: 1,
        duration: 0.5,
        ease: "sine.inOut",
        stagger: {
          each: 0.01,
          ease: "sine.inOut",
        },
      });
      return tl;
    },
  }));


  return <div className={`initialInvis ${className} ${css.rocketModal}`} ref={root}>
    <div className={css.inner}>
      <div className={css.intro} ref={intro}>

        <div className={css.headline}>
          <span className="piece">What Is&nbsp;</span>
          <span className={`piece ${css.red}`}>GETTR</span>
          <span className="piece">?</span>
        </div>
        <div>
          <span className="piece">A world-wide&nbsp;</span>
          <span className="piece">welcome,</span>
        </div>
        <div>
          <span className="piece">Deepening human&nbsp;</span>
          <span className="piece">connection,&nbsp;</span>
        </div>
        <div>
          <span className="piece">Finding fanbase&nbsp;</span>
          <span className="piece">for the inspired,&nbsp;</span>
        </div>
        <div>
          <span className="piece">A modern pathway&nbsp;</span>
          <span className="piece">to economic opportunity,&nbsp;</span>
        </div>
        <div>
          <span className="piece">Transparency in&nbsp;</span>
          <span className="piece">an era where&nbsp;</span>
          <span className="piece">communication&nbsp;</span>
          <span className="piece">has gone awry.&nbsp;</span>
        </div>
        <div>
          <span className="piece">And we are&nbsp;</span>
          <span className="piece">just getting started.&nbsp;</span>
        </div>


        <div className={css.buttons}>
          <Button id="outroStep1Exit" onClick={handleExit} className={`piece ${css.button}`} ghost><ArrowLeftSvg/> <span>Exit</span></Button>
          <Button id="outroStep1Join" href="https://gettr.com/onboarding" className={`piece ${css.button}`}><span>Join Us</span> <ArrowRightSvg/></Button>
        </div>
      </div>
    </div>
  </div>;
});


