import React, { useEffect, forwardRef, useImperativeHandle, useRef, useState } from "react";
import css from "./index.module.scss";
import gsap from "@/scripts/gsap";
import Button from "@/components/button";
import ArrowRightSvg from "/public/icons/arrowRight.svg";
import ArrowLeftSvg from "/public/icons/arrowLeft.svg";
import { DrawSVGPlugin } from "@/scripts/gsap/DrawSVGPlugin";



const entries = [
  "In platform video editing, with 100k songs for use",
  "AI-based image editing",
  "4k live streaming",
  "Real time subtitle translation",
  "3 minute long video posts",
  "Smart recommendations",
];

export default forwardRef(({
  className = "",
  exit = () => {},
}, ref) => {
  const intro = useRef();
  const statistics = useRef();
  const root = useRef();
  const entriesRefs = useRef([]);

  function toStatistics(){
    let tl = gsap.timeline();
    tl.to(intro.current, {
      opacity: 0,
      pointerEvents: "none",
      duration: 0.5,
    }, 0);
    tl.set(statistics.current, {visibility: "visible"});
    tl.to(statistics.current, {
      opacity: 1,
      pointerEvents: "all",
      duration: 0.5,
    }, 0);

    tl.fromTo(statistics.current.querySelectorAll(".piece"), {
      opacity: 0,
    }, {
      opacity: 1,
      duration: 0.5,
      ease: "sine.inOut",
      stagger: {
        each: 0.1,
        ease: "sine.inOut",
      },
    }, 0);
  }


  function toIntro(){
    let tl = gsap.timeline();
    tl.to(statistics.current, {
      opacity: 0,
      pointerEvents: "none",
      duration: 0.5,
    }, 0);
    tl.set(intro.current, {opacity: 1, pointerEvents: "all"}, 0);
    tl.fromTo(intro.current.querySelectorAll(".piece"), {
      opacity: 0,
    }, {
      opacity: 1,
      duration: 0.5,
      ease: "sine.inOut",
      stagger: {
        each: 0.01,
        ease: "sine.inOut",
      },
    }, "<75%");
  }

  function handleExit(){
    gsap.to([statistics.current, intro.current, root.current], {
      opacity: 0,
      pointerEvents: "none",
      duration: 0.5,
      ease: "sine.inOut",
    });
    exit();
  }

  useEffect(() => {
    gsap.registerPlugin(DrawSVGPlugin);
  }, []);


  useImperativeHandle(ref, () => ({
    show(target){
      let tl = gsap.timeline();
      tl.set(root.current, {visibility: "visible", opacity: 0, pointerEvents: "none"});
      tl.set(intro.current, {visibility: "visible", opacity: 1, pointerEvents: "all"});
      tl.set(statistics.current, {visibility: "visible", opacity: 0, pointerEvents: "none"});
      tl.to(root.current, {
        opacity: 1,
        pointerEvents: "all",
        duration: 0.5,
        ease: "sine.inOut",
      }, 0);

      tl.fromTo(intro.current.querySelectorAll(".piece"), {
        opacity: 0,
      }, {
        opacity: 1,
        duration: 0.5,
        ease: "sine.inOut",
        stagger: {
          each: 0.01,
          ease: "sine.inOut",
        },
      });
      return tl;
    },
  }));


  return <div className={`initialInvis ${className} ${css.rocketModal}`} ref={root}>
    <div className={css.inner}>
      <div className={css.intro} ref={intro}>
        <div className={css.superHeadline}>
          <span className="piece">Live Stream Views:&nbsp;</span>
          <b className="piece">475M+&nbsp;</b>
          <span className="piece">|&nbsp;</span>
          <span className="piece">Hours of Video Watched:&nbsp;</span>
          <b className="piece">5.1M+</b>
        </div>
        <div className={css.headline}>

          <span className="piece">GETTR provides a&nbsp;</span>
          <span className="piece">versatile and&nbsp;</span>
          <span className="piece">user-friendly social&nbsp;</span>
          <span className="piece">media experience while&nbsp;</span>
          <span className="piece">opposing censorship and&nbsp;</span>
          <span className="piece">maintaining an unwavering&nbsp;</span>
          <span className="piece">commitment to&nbsp;</span>
          <span className={`piece ${css.red}`}>free speech.&nbsp;</span>
        </div>
        <p>
          <span className="piece">GETTR allows users&nbsp;</span>
          <span className="piece">to create posts up&nbsp;</span>
          <span className="piece">to 777 characters long,&nbsp;</span>
          <span className="piece">upload and edit videos&nbsp;</span>
          <span className="piece">up to 3 minutes long,&nbsp;</span>
          <span className="piece">and attach up to six&nbsp;</span>
          <span className="piece">images per post. To&nbsp;</span>
          <span className="piece">cater to users who&nbsp;</span>
          <span className="piece">enjoy short-form content,&nbsp;</span>
          <span className="piece">the platform launched&nbsp;</span>
          <span className="piece">GTok, a feature that&nbsp;</span>
          <span className="piece">enables users to create&nbsp;</span>
          <span className="piece">short, vertical videos&nbsp;</span>
          <span className="piece">with access to unique&nbsp;</span>
          <span className="piece">filters and a vast library&nbsp;</span>
          <span className="piece">of up to 10,000 songs.&nbsp;</span>
        </p>
        <p>
          <span className="piece">This focus on multimedia&nbsp;</span>
          <span className="piece">differentiates GETTR from&nbsp;</span>
          <span className="piece">its competitors and empowers&nbsp;</span>
          <span className="piece">its users with options&nbsp;</span>
          <span className="piece">for clarity, communication,&nbsp;</span>
          <span className="piece">and self-expression.&nbsp;</span>
        </p>
        <div className={css.buttons}>
          <Button id="gearsStep1Exit" onClick={handleExit} className={`piece ${css.button}`} ghost><ArrowLeftSvg/> <span>Exit</span></Button>
          <Button id="gearsStep1Next" onClick={toStatistics} className={`piece ${css.button}`}><span>Next</span> <ArrowRightSvg/></Button>
        </div>
      </div>

      <div className={`initialInvis ${css.statistics}`} ref={statistics}>
        <div className={css.headline}>Encouraging Engagement and Inclusivity With</div>
        <div className={css.list}>
          {entries.map((entry, idx) => <div key={entry} className={`piece ${css.entry}`}>{entry}</div>)}
        </div>
        <div className={css.buttons}>
          <Button id="gearsStep2Back" onClick={toIntro} className={`piece ${css.button}`} ghost><ArrowLeftSvg/> <span>Back</span></Button>
          <Button id="gearsStep2Next" onClick={handleExit} className={`piece ${css.button}`}><span>Next</span><ArrowRightSvg/></Button>
        </div>
      </div>
    </div>
  </div>;
});


