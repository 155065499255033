import Head from "next/head";
import React, { useEffect, useRef, useState } from "react";
import css from "./index.module.scss";
import gsap from "@/scripts/gsap";
import CustomEase from "@/scripts/gsap/CustomEase";
import CustomWiggle from "@/scripts/gsap/CustomWiggle";
import { MotionPathPlugin } from "@/scripts/gsap/MotionPathPlugin";
import { MorphSVGPlugin } from "@/scripts/gsap/MorphSVGPlugin";
import Button from "@/components/button";
import ArrowRightSvg from "/public/icons/arrowRight.svg";
import ArrowLeftSvg from "/public/icons/arrowLeft.svg";
import ChartSvg from "/public/icons/chart.svg";
import Shards from "@/components/shards";
import Poem from "@/components/poem";
import SceneHeadline from "@/components/sceneHeadline";
import Modals from "@/components/modals";


gsap.registerPlugin(CustomEase, CustomWiggle);
CustomWiggle.create("screenShake", {wiggles: 6});


function spanFadein(selector){
  let tl = gsap.timeline();
  tl.set(selector, {visibility: "visible", pointerEvents: "all"});
  tl.fromTo(selector, {
    opacity: 0,
  }, {
    opacity: 1,
    ease: "sine.inOut",
    duration: 0.5,
    stagger: {
      each: 0.1,
      ease: "sine.inOut",
    },
  });
  return tl;
}



export default function Home() {
  const root = useRef();

  const [sceneTls, setSceneTls] = useState([]);
  const shards = useRef();
  const poem = useRef();
  const modals = useRef();
  const sceneHeadline = useRef();
  const [scene, setScene] = useState(0);


  function resetSceneTls(){
    for (let tl of sceneTls){
      tl.kill();
    }
  }


  const SCENES = {
    //logo
    0: {
      headline: `.${css.headline} div:nth-child(1) *`,
      subheadline: `.${css.subheadline} div:nth-child(1) *`,
      buttons: `.${css.controls} div:nth-child(1) *`,
      background: "linear-gradient (30deg, #eeeeee, #eeeeee)",
      previous: () => {},
      start: () => {
        //show the four lines of the opening poem
        let tl = gsap.timeline({});

        tl.add(poem.current.transition());
        tl.add(shards.current.transition("outro"));

        //fade in the headline/subheadline/button

        tl.add(sceneHeadline.current.transition("intro"));
      },
      next: () => {
        shards.current.transition("rocket");
        setScene(1);

        let tl = gsap.timeline();
        tl.add(sceneHeadline.current.transition(null));
        tl.fromTo(root.current, {background: SCENES[0].background}, {background: SCENES[1].background, duration: 2, ease: "sine.inOut"}, 0);
        tl.add(sceneHeadline.current.transition("rocket"));
      },
    },

    //rocket
    1: {
      headline: `.${css.headline} div:nth-child(2) *`,
      subheadline: `.${css.subheadline} div:nth-child(2) *`,
      background: "linear-gradient(30deg, #b2ebf6, #e8fdfd)",
      previous: () => {
      },
      next: () => {
        shards.current.transition("earth");
        setScene(2);

        //update headline and controls
        let tl = gsap.timeline();
        tl.add(sceneHeadline.current.transition(null));
        tl.to(root.current, {background: SCENES[2].background, duration: 2, ease: "sine.inOut"}, 0);
        tl.add(sceneHeadline.current.transition("earth"));
      },
      showData: () => {
        resetSceneTls();
        let tl = shards.current.transition("circle");
        tl.add(modals.current.show("rocket"), 1);
      },
      hideData: () => {
        resetSceneTls();
        shards.current.transition("rocket");
      },
    },

    //earth
    2: {
      headline: `.${css.headline} div:nth-child(3) *`,
      subheadline: `.${css.subheadline} div:nth-child(2) *`,
      background: "linear-gradient(30deg, #cdadf8, #f5d7fb)",
      previous: () => {
        shards.current.transition("rocket");
        setScene(1);

        //update headline and controls
        let tl = gsap.timeline();
        tl.add(sceneHeadline.current.transition(null));
        tl.to(root.current, {background: SCENES[1].background, duration: 2, ease: "sine.inOut"}, 0);
        tl.add(sceneHeadline.current.transition("rocket"));
      },
      next: () => {
        shards.current.transition("gears");
        setScene(3);

        //update headline and controls
        let tl = gsap.timeline();
        tl.add(sceneHeadline.current.transition(null));
        tl.to(root.current, {background: SCENES[3].background, duration: 2, ease: "sine.inOut"}, 0);
        tl.add(sceneHeadline.current.transition("gears"));
      },
      showData: () => {
        resetSceneTls();
        let tl = shards.current.transition("circle");
        tl.add(modals.current.show("earth"), 1);
      },
      hideData: () => {
        resetSceneTls();
        shards.current.transition("earth");
      },
    },

    //gears
    3: {
      headline: `.${css.headline} div:nth-child(4) *`,
      subheadline: `.${css.subheadline} div:nth-child(2) *`,
      background: "linear-gradient(30deg, #dd9a6f, #f7cfb6)",
      previous: () => {
        shards.current.transition("earth");
        setScene(2);

        //update headline and controls
        let tl = gsap.timeline();
        tl.add(sceneHeadline.current.transition(null));
        tl.to(root.current, {background: SCENES[2].background, duration: 2, ease: "sine.inOut"}, 0);
        tl.add(sceneHeadline.current.transition("earth"));
      },
      next: () => {
        shards.current.transition("boat");
        setScene(4);

        //update headline and controls
        let tl = gsap.timeline();
        tl.add(sceneHeadline.current.transition(null));
        tl.to(root.current, {background: SCENES[4].background, duration: 2, ease: "sine.inOut"}, 0);
        tl.add(sceneHeadline.current.transition("boat"));
      },
      showData: () => {
        resetSceneTls();
        let tl = shards.current.transition("circle");
        tl.add(modals.current.show("gears"), 1);
      },
      hideData: () => {
        resetSceneTls();
        shards.current.transition("gears");
      },
    },

    //boat
    4: {
      headline: `.${css.headline} div:nth-child(5) *`,
      subheadline: `.${css.subheadline} div:nth-child(2) *`,
      background: "linear-gradient(30deg, #adc4f8, #bcf9f1)",
      previous: () => {
        shards.current.transition("gears");
        setScene(3);

        //update headline and controls
        let tl = gsap.timeline();
        tl.add(sceneHeadline.current.transition(null));
        tl.to(root.current, {background: SCENES[3].background, duration: 2, ease: "sine.inOut"}, 0);
        tl.add(sceneHeadline.current.transition("gears"));
      },
      next: () => {
        shards.current.transition("outro");
        setScene(5);

        //update headline and controls
        let tl = gsap.timeline();
        tl.add(sceneHeadline.current.transition(null));
        tl.to(root.current, {background: SCENES[5].background, duration: 2, ease: "sine.inOut"}, 0);
        tl.add(sceneHeadline.current.transition("outro"));
      },
      showData: () => {
        resetSceneTls();
        let tl = shards.current.transition("circle");
        tl.add(modals.current.show("boat"), 1);
      },
      hideData: () => {
        resetSceneTls();
        shards.current.transition("boat");
      },
    },

    //outro
    5: {
      headline: `.${css.headline} div:nth-child(6) *`,
      subheadline: `.${css.subheadline} div:nth-child(2) *`,
      background: "linear-gradient(30deg, #eeeeee, #eeeeee)",
      previous: () => {
        shards.current.transition("boat");
        setScene(4);

        //update headline and controls
        let tl = gsap.timeline();
        tl.add(sceneHeadline.current.transition(null));
        tl.to(root.current, {background: SCENES[4].background, duration: 2, ease: "sine.inOut"}, 0);
        tl.add(sceneHeadline.current.transition("boat"));
      },
      next: () => {
      },
      showData: () => {
        resetSceneTls();
        let tl = shards.current.transition("circle");
        tl.add(modals.current.show("outro"), 1);
      },
      hideData: () => {
        resetSceneTls();
        shards.current.transition("outro");
      },
    },
  };


  useEffect(() => {
    gsap.registerPlugin(MotionPathPlugin);
    gsap.registerPlugin(MorphSVGPlugin);
    SCENES[0].start();
  }, []);

  return (
    <div className="root">
      <Head>
        <title>GETTR - The Marketplace of Ideas</title>
        <meta name="description" content="Social media is BETTR with GETTR. BETTR community. BETTR technology. BETTR opportunity."/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        <meta property="og:description" content="Social media is BETTR with GETTR. BETTR community. BETTR technology. BETTR opportunity."/>
        <meta property="og:title" content="GETTR - The Marketplace of Ideas"/>
        <meta property="og:image" content="https://showcase.gettr.com/images/shareCard/shareCard.png"/>
      </Head>
      <main className={css.main} >
        <section className={css.fold} ref={root}>
          <Shards className={css.shards} ref={shards}/>

          <div className={css.poemWrapper}>
            <Poem className={css.poem} ref={poem}/>
          </div>

          <div className={css.sceneHeadlineWrapper}>
            <SceneHeadline
              previous={SCENES[scene].previous}
              next={SCENES[scene].next}
              showData={SCENES[scene].showData}
              hideData={SCENES[scene].hideData}
              ref={sceneHeadline}
            />
          </div>

          <div className={css.modalsWrapper}>
            <Modals ref={modals} exit={SCENES[scene].hideData}/>
          </div>
        </section>
      </main>
    </div>
  );
}

/*


          <div className={css.data}>
            <div className={css.frame}>
              <div className={css.frameInner}>
                <div className={css.textSlide}>
                  <div className={css.dataSuperHeadline}>
                    <span className={css.label}>Total Accounts:&nbsp;</span>
                    <b className={css.stat}>7,800,000+</b>
                    <span className={css.separator}>|</span>
                    <span className={css.label}>Total Accounts:&nbsp;</span>
                    <b className={css.stat}>7,800,000+</b>
                  </div>
                  <p>GETTR achieved a remarkable feat by garnering 1 million users within a mere three days after its launch on July 4, 2021.</p>
                  <p>In January 2022, GETTR&apos;s growth soared with an extraordinary 1,000,000 new users on the platform.</p>
                  <p>Amidst the fluctuating landscape, GETTR had lift off as the sole alternative social media platform to witness a surge in year-over-year (YoY) unique visitors to the site between 2021 and 2022, experiencing a remarkable growth rate of 50%.</p>
                  <p>In the United States, GETTR became the most downloaded social networking app on Google Play Store and the second most downloaded on Apple&apos;s App Store in early March 2022.</p>
                  <div className={css.buttons}>
                    <Button>Back</Button>
                    <Button>Statistics</Button>
                  </div>
                </div>
                <div className={css.statSlide}>
                  <GrowthGraphSvg/>
                </div>
              </div>
            </div>
          </div>
          */
