import React, { forwardRef, useImperativeHandle, useRef, useState } from "react";
import css from "./index.module.scss";
import gsap from "@/scripts/gsap";
import RocketModal from "./rocketModal";
import EarthModal from "./earthModal";
import GearsModal from "./gearsModal";
import BoatModal from "./boatModal";
import OutroModal from "./outroModal";


export default forwardRef(({
  className = "",
  exit = () => {},
}, ref) => {
  const [current, setCurrent] = useState(null);
  const [modals, setModals] = useState({
    rocket: useRef(),
    earth: useRef(),
    gears: useRef(),
    boat: useRef(),
    outro: useRef(),
  });


  useImperativeHandle(ref, () => ({
    show(target){
      return modals[target].current.show();
    },
  }));


  return <div className={css.modals}>
    <RocketModal className={css.modal} ref={modals.rocket} exit={exit}/>
    <EarthModal className={css.modal} ref={modals.earth} exit={exit}/>
    <GearsModal className={css.modal} ref={modals.gears} exit={exit}/>
    <BoatModal className={css.modal} ref={modals.boat} exit={exit}/>
    <OutroModal className={css.modal} ref={modals.outro} exit={exit}/>
  </div>;
});


