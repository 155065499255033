import Link from "next/link";
import React, {forwardRef} from "react";
import css from "./index.module.scss";
import mpTag from "@/scripts/tag/mpTag";
import gtmTag from "@/scripts/tag/gtmTag";

export default forwardRef(({
  ghost = false,
  dark = false,
  grayscale = false,
  href = "",
  onClick = null,
  children,
  className = "",
  icon = null,
  disabled = false,
  id = null,
}, ref) => {
  let thisClassName = `custom ${className} ${css.button}`;
  thisClassName += ` ${ghost ? css.ghost : ""}`;
  thisClassName += ` ${dark ? css.dark : ""}`;
  thisClassName += ` ${grayscale ? css.grayscale : ""}`;
  thisClassName += ` ${icon ? css.icon : ""}`;
  thisClassName += ` ${disabled ? css.disabled : ""}`;

  function handleClick(){
    mpTag("buttonClicked", {
      href,
      text: `${children}`,
      buttonId: id,
    });
    gtmTag(`buttonClick_${id}`);
    if (onClick){
      onClick();
    }
  }

  if (href){
    return <Link
      href={href}
    >
      <a
        id={id}
        className={thisClassName}
        onClick={handleClick}
      >{icon || children}</a>
    </Link>;
  }
  return <button
    id={id}
    className={thisClassName}
    onClick={handleClick}
  >{icon || children}</button>;
});
