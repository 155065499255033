import React, { useEffect, forwardRef, useImperativeHandle, useRef, useState } from "react";
import css from "./index.module.scss";
import gsap from "@/scripts/gsap";
import Button from "@/components/button";
import ArrowRightSvg from "/public/icons/arrowRight.svg";
import ArrowLeftSvg from "/public/icons/arrowLeft.svg";
import GrowthGraph from "./growthGraph.svg";
import { DrawSVGPlugin } from "@/scripts/gsap/DrawSVGPlugin";



export default forwardRef(({
  className = "",
  exit = () => {},
}, ref) => {
  const intro = useRef();
  const statistics = useRef();
  const root = useRef();

  function toStatistics(){
    let tl = gsap.timeline();
    tl.to(intro.current, {
      opacity: 0,
      pointerEvents: "none",
      duration: 0.5,
    }, 0);
    tl.set(statistics.current, {visibility: "visible"});
    tl.to(statistics.current, {
      opacity: 1,
      pointerEvents: "all",
      duration: 0.5,
    }, 0);

    tl.fromTo(statistics.current.querySelector(".border"), {
      drawSVG: "50% 50%",
    }, {
      drawSVG: "0% 100%",
      duration: 0.5,
      ease: "sine.inOut",
    });

    tl.fromTo(statistics.current.querySelectorAll(".bar"), {
      scaleY: 0,
      transformOrigin: "center bottom",
    }, {
      scaleY: 1,
      duration: 0.5,
      ease: "sine.inOut",
      stagger: {
        each: 0.1,
        ease: "sine.inOut",
      },
    }, "<25%");

    tl.fromTo(statistics.current.querySelectorAll(".xLabel"), {
      opacity: 0,
    }, {
      opacity: 1,
      duration: 0.5,
      ease: "sine.inOut",
      stagger: {
        each: 0.1,
        ease: "sine.inOut",
      },
    }, "<0.1");

    tl.fromTo(statistics.current.querySelectorAll(".yLabel"), {
      opacity: 0,
    }, {
      opacity: 1,
      duration: 0.5,
      ease: "sine.inOut",
      stagger: {
        each: 0.05,
        ease: "sine.inOut",
      },
    }, "<");

    tl.fromTo(statistics.current.querySelectorAll(`.${css.buttons} > *`), {
      opacity: 0,
    }, {
      opacity: 1,
      duration: 0.5,
      ease: "sine.inOut",
      stagger: {
        each: 0.1,
        ease: "sine.inOut",
      },
    }, "<75%");
  }


  function toIntro(){
    let tl = gsap.timeline();
    tl.to(statistics.current, {
      opacity: 0,
      pointerEvents: "none",
      duration: 0.5,
    }, 0);
    tl.set(intro.current, {opacity: 1, pointerEvents: "all"}, 0);
    tl.fromTo(intro.current.querySelectorAll(".piece"), {
      opacity: 0,
    }, {
      opacity: 1,
      duration: 0.5,
      ease: "sine.inOut",
      stagger: {
        each: 0.01,
        ease: "sine.inOut",
      },
    }, "<75%");
  }

  function handleExit(){
    gsap.to([statistics.current, intro.current, root.current], {
      opacity: 0,
      pointerEvents: "none",
      duration: 0.5,
      ease: "sine.inOut",
    });
    exit();
  }

  useEffect(() => {
    gsap.registerPlugin(DrawSVGPlugin);
  }, []);


  useImperativeHandle(ref, () => ({
    show(target){
      let tl = gsap.timeline();
      tl.set(root.current, {visibility: "visible", opacity: 0, pointerEvents: "none"});
      tl.set(intro.current, {visibility: "visible", opacity: 1, pointerEvents: "all"});
      tl.set(statistics.current, {visibility: "visible", opacity: 0, pointerEvents: "none"});
      tl.to(root.current, {
        opacity: 1,
        pointerEvents: "all",
        duration: 0.5,
        ease: "sine.inOut",
      }, 0);

      tl.fromTo(intro.current.querySelectorAll(".piece"), {
        opacity: 0,
      }, {
        opacity: 1,
        duration: 0.5,
        ease: "sine.inOut",
        stagger: {
          each: 0.01,
          ease: "sine.inOut",
        },
      });
      return tl;
    },
  }));


  return <div className={`initialInvis ${className} ${css.rocketModal}`} ref={root}>
    <div className={css.inner}>
      <div className={css.intro} ref={intro}>
        <div className={css.superHeadline}>
          <span className="piece">Total Accounts:&nbsp;</span>
          <b className="piece">9,200,000+</b>
        </div>
        <div className={css.headline}>
          <span className="piece">GETTR achieved&nbsp;</span>
          <span className="piece">a remarkable&nbsp;</span>
          <span className="piece">feat by&nbsp;</span>
          <span className="piece">garnering&nbsp;</span>
          <span className={`piece ${css.red}`}>1 million users&nbsp;</span>
          <span className="piece"> within a mere&nbsp;</span>
          <span className="piece">three days&nbsp;</span>
          <span className="piece">after its&nbsp;</span>
          <span className="piece">launch on&nbsp;</span>
          <span className="piece">July 4, 2021.</span>
        </div>
        <p>
          <span className="piece">In January 2022,&nbsp;</span>
          <span className="piece">GETTR&apos;s growth&nbsp;</span>
          <span className="piece">soared with an&nbsp;</span>
          <span className="piece">extraordinary&nbsp;</span>
          <span className={`piece ${css.red}`}>1,000,000&nbsp;</span>
          <span className="piece">new users&nbsp;</span>
          <span className="piece">on the platform.</span>
        </p>
        <p>
          <span className="piece">Amidst the&nbsp;</span>
          <span className="piece">fluctuating&nbsp;</span>
          <span className="piece">landscape,&nbsp;</span>
          <span className="piece">GETTR had lift off&nbsp;</span>
          <span className="piece">as the sole&nbsp;</span>
          <span className="piece">alternative&nbsp;</span>
          <span className="piece">social media platform&nbsp;</span>
          <span className="piece">to witness a surge&nbsp;</span>
          <span className="piece">in year-over-year (YoY)&nbsp;</span>
          <span className="piece">unique visitors&nbsp;</span>
          <span className="piece">to the site between&nbsp;</span>
          <span className="piece">2021 and 2022,&nbsp;</span>
          <span className="piece">experiencing&nbsp;</span>
          <span className="piece">a remarkable&nbsp;</span>
          <span className="piece">growth rate&nbsp;</span>
          <span className="piece">of 50%.</span>
        </p>

        <p>
          <span className="piece">In the&nbsp;</span>
          <span className="piece">United States,&nbsp;</span>
          <span className="piece">GETTR became&nbsp;</span>
          <span className="piece">the most&nbsp;</span>
          <span className="piece">downloaded&nbsp;</span>
          <span className="piece">social networking app&nbsp;</span>
          <span className="piece"> on Google Play Store&nbsp;</span>
          <span className="piece">and the second&nbsp;</span>
          <span className="piece">most downloaded&nbsp;</span>
          <span className="piece">on Apple&apos;s&nbsp;</span>
          <span className="piece">App Store&nbsp;</span>
          <span className="piece">in early March 2022.&nbsp;</span>
        </p>
        <div className={css.buttons}>
          <Button id="rocketStep1Exit"onClick={handleExit} className={`piece ${css.button}`} ghost><ArrowLeftSvg/> <span>Exit</span></Button>
          <Button id="rocketStep1Next"onClick={toStatistics} className={`piece ${css.button}`}><span>Next</span> <ArrowRightSvg/></Button>
        </div>
      </div>

      <div className={`initialInvis ${css.statistics}`} ref={statistics}>
        <div className={css.headline}>2022 Growth</div>
        <div className={css.graph}>
          <GrowthGraph/>
        </div>
        <div className={css.buttons}>
          <Button id="rocketStep2Back" onClick={toIntro} className={css.button} ghost><ArrowLeftSvg/> <span>Back</span></Button>
          <Button id="rocketStep2Next" onClick={handleExit} className={css.button}><span>Next</span><ArrowRightSvg/></Button>
        </div>
      </div>
    </div>
  </div>;
});


