import React, { useEffect, forwardRef, useImperativeHandle, useRef, useState } from "react";
import css from "./index.module.scss";
import gsap from "@/scripts/gsap";
import Button from "@/components/button";
import ArrowRightSvg from "/public/icons/arrowRight.svg";
import ArrowLeftSvg from "/public/icons/arrowLeft.svg";
import { DrawSVGPlugin } from "@/scripts/gsap/DrawSVGPlugin";

const entries = [
  {
    date: "February 28, 2022",
    text: ["GETTR became", "the top free", "app on Apple's", "App Store in Brazil."],
  },
  {
    date: "March 1, 2022",
    text: ["GETTR became the", "most downloaded", "social networking", "app on Google", "Play Store in Brazil."],
  },
  {
    date: "March 2, 2022",
    text: ["GETTR became", "the second most", "downloaded social networking", "app on Apple's App", "Store in the", "United States."],
  },
  {
    date: "March 3, 2022",
    text: ["GETTR became the", "most downloaded", "social networking app", "on Google Play Store", "in the United States."],
  },
  {
    date: "March 4, 2022",
    text: ["GETTR became the", "top free app on", "Apple's App Store", "in Canada."],
  },
  {
    date: "March 5, 2022",
    text: ["GETTR became", "the most downloaded", "social networking app", "on Google Play", "Store in Canada."],
  },
  {
    date: "March 6, 2022",
    text: ["GETTR became the", "top free app on", "Apple's App Store", "in Australia."],
  },
];

export default forwardRef(({
  className = "",
  exit = () => {},
}, ref) => {
  const intro = useRef();
  const statistics = useRef();
  const root = useRef();
  const entriesRefs = useRef([]);

  function toStatistics(){
    let tl = gsap.timeline();
    tl.to(intro.current, {
      opacity: 0,
      pointerEvents: "none",
      duration: 0.5,
    }, 0);
    tl.set(statistics.current, {visibility: "visible"});
    tl.to(statistics.current, {
      opacity: 1,
      pointerEvents: "all",
      duration: 0.5,
    }, 0);

    for (let i = 0; i < entriesRefs.current.length; i++){
      let subTl = gsap.timeline();
      subTl.fromTo(entriesRefs.current[i].querySelector(`.${css.date}`), {
        opacity: 0,
      }, {
        opacity: 1,
        duration: 0.125,
        ease: "sine.inOut",
      }, 0);


      subTl.fromTo(entriesRefs.current[i].querySelector(`.${css.before}`), {
        scaleX: 0,
        transformOrigin: "center right",
      }, {
        scaleX: 1,
        duration: 0.125,
        ease: "sine.inOut",
      }, "<50%");
      subTl.fromTo(entriesRefs.current[i].querySelector(`.${css.after}`), {
        scaleX: 0,
        transformOrigin: "center left",
      }, {
        scaleX: 1,
        duration: 0.125,
        ease: "sine.inOut",
      }, "<");

      subTl.fromTo(entriesRefs.current[i].querySelectorAll(".piece"), {
        opacity: 0,
      }, {
        opacity: 1,
        duration: 0.125,
        ease: "sine.inOut",
      }, 0.025);
      tl.add(subTl, "<0.05");
    }

    tl.fromTo(statistics.current.querySelectorAll(`.${css.buttons} > *`), {
      opacity: 0,
    }, {
      opacity: 1,
      duration: 0.125,
      ease: "sine.inOut",
      stagger: {
        each: 0.05,
      },
    });
  }


  function toIntro(){
    let tl = gsap.timeline();
    tl.to(statistics.current, {
      opacity: 0,
      pointerEvents: "none",
      duration: 0.5,
    }, 0);
    tl.set(intro.current, {opacity: 1, pointerEvents: "all"}, 0);
    tl.fromTo(intro.current.querySelectorAll(".piece"), {
      opacity: 0,
    }, {
      opacity: 1,
      duration: 0.5,
      ease: "sine.inOut",
      stagger: {
        each: 0.01,
        ease: "sine.inOut",
      },
    }, "<75%");
  }

  function handleExit(){
    gsap.to([statistics.current, intro.current, root.current], {
      opacity: 0,
      pointerEvents: "none",
      duration: 0.5,
      ease: "sine.inOut",
    });
    exit();
  }

  useEffect(() => {
    gsap.registerPlugin(DrawSVGPlugin);
  }, []);


  useImperativeHandle(ref, () => ({
    show(target){
      let tl = gsap.timeline();
      tl.set(root.current, {visibility: "visible", opacity: 0, pointerEvents: "none"});
      tl.set(intro.current, {visibility: "visible", opacity: 1, pointerEvents: "all"});
      tl.set(statistics.current, {visibility: "visible", opacity: 0, pointerEvents: "none"});
      tl.to(root.current, {
        opacity: 1,
        pointerEvents: "all",
        duration: 0.5,
        ease: "sine.inOut",
      }, 0);

      tl.fromTo(intro.current.querySelectorAll(".piece"), {
        opacity: 0,
      }, {
        opacity: 1,
        duration: 0.5,
        ease: "sine.inOut",
        stagger: {
          each: 0.01,
          ease: "sine.inOut",
        },
      });
      return tl;
    },
  }));


  return <div className={`initialInvis ${className} ${css.rocketModal}`} ref={root}>
    <div className={css.inner}>
      <div className={css.intro} ref={intro}>
        <div className={css.superHeadline}>
          <span className="piece">Countries with GETTR:&nbsp;</span>
          <b className="piece">192+&nbsp;</b>
          <span className="piece">|&nbsp;</span>
          <span className="piece">Supported Languages:&nbsp;</span>
          <b className="piece">8</b>
        </div>
        <div className={css.headline}>
          <span className="piece">GETTR traveled&nbsp;</span>
          <span className="piece">around the world&nbsp;</span>
          <span className="piece">in 80 weeks,&nbsp;</span>
          <span className="piece">with a presence in&nbsp;</span>
          <span className="piece">192 countries and&nbsp;</span>
          <span className="piece">a community of&nbsp;</span>
          <span className={`piece ${css.red}`}>9.2+ million strong.&nbsp;</span>
        </div>
        <p>
          <span className="piece">GETTR&apos;s live streams&nbsp;</span>
          <span className="piece">support real time&nbsp;</span>
          <span className="piece">subtitle translations in&nbsp;</span>
          <span className="piece">eight languages,&nbsp;</span>
          <span className="piece">maximizing global audience&nbsp;</span>
          <span className="piece">appeal. These streams&nbsp;</span>
          <span className="piece">amassed 310M views two&nbsp;</span>
          <span className="piece">months after launch,&nbsp;</span>
          <span className="piece">catapulting GETTR to the&nbsp;</span>
          <span className="piece">top of Apple&apos;s app store&nbsp;</span>
          <span className="piece">in Brazil, Australia,&nbsp;</span>
          <span className="piece">and Canada, and the &nbsp;</span>
          <span className="piece">most downloaded social networking app&nbsp;</span>
          <span className="piece">on Google Play store&nbsp;</span>
          <span className="piece">in the United States.&nbsp;</span>
        </p>
        <p>
          <span className="piece">GETTR gained traction&nbsp;</span>
          <span className="piece">in Japan (45% user&nbsp;</span>
          <span className="piece">base increase in a week)&nbsp;</span>
          <span className="piece">and China (by supporting&nbsp;</span>
          <span className="piece">the &quot;blank paper&quot; anti-censorship)&nbsp;</span>
          <span className="piece">movement. As Australian&nbsp;</span>
          <span className="piece">users jumped 10,000% and&nbsp;</span>
          <span className="piece">GETTR spread across&nbsp;</span>
          <span className="piece">Colombia and France,&nbsp;</span>
          <span className="piece">the site challenged the&nbsp;</span>
          <span className="piece">status quo, showcasing&nbsp;</span>
          <span className="piece">exclusive high-profile&nbsp;</span>
          <span className="piece">events while protecting&nbsp;</span>
          <span className="piece">user voices in countries&nbsp;</span>
          <span className="piece">like Brazil.&nbsp;</span>
        </p>

        <div className={css.buttons}>
          <Button id="earthStep1Exit" onClick={handleExit} className={`piece ${css.button}`} ghost><ArrowLeftSvg/> <span>Exit</span></Button>
          <Button id="earthStep1Next" onClick={toStatistics} className={`piece ${css.button}`}><span>Next</span> <ArrowRightSvg/></Button>
        </div>
      </div>

      <div className={`initialInvis ${css.statistics}`} ref={statistics}>
        <div className={css.headline}>Top App Quick Facts</div>
        <div className={css.list}>
          {entries.map((entry, idx) => <div key={entry.date} className={css.entry} ref={(element) => (entriesRefs.current[idx] = element)}>
            <div>
              <span className={css.before}></span>
              <span className={css.date}>February 28, 2022</span>
              <span className={css.after}></span>
            </div>
            <div className="piece">
              {entry.text.map((piece) => <span key={piece}>{piece}&nbsp;</span>)}
            </div>
          </div>)}
        </div>
        <div className={css.buttons}>
          <Button id="earthStep2Back" onClick={toIntro} className={css.button} ghost><ArrowLeftSvg/> <span>Back</span></Button>
          <Button id="earthStep2Next" onClick={handleExit} className={css.button}><span>Next</span><ArrowRightSvg/></Button>
        </div>
      </div>
    </div>
  </div>;
});


