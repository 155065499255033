import React, { useEffect, forwardRef, useImperativeHandle, useRef, useState } from "react";
import css from "./index.module.scss";
import CircleShards from "/public/shards/circle.svg";
import GettrShards from "/public/shards/gettr.svg";
import RocketShards from "/public/shards/rocket.svg";
import EarthShards from "/public/shards/earth.svg";
import BaseShards from "/public/shards/base.svg";
import BoatShards from "/public/shards/boat.svg";
import GearsShards from "/public/shards/gears.svg";
import gsap from "@/scripts/gsap";
import {shuffle} from "@/scripts/utils";
import Button from "@/components/button";
import ArrowRightSvg from "/public/icons/arrowRight.svg";
import ArrowLeftSvg from "/public/icons/arrowLeft.svg";
import ChartSvg from "/public/icons/chart.svg";


function spanFadein(elements){
  let tl = gsap.timeline();
  tl.set(elements, {visibility: "visible", pointerEvents: "all"});
  tl.fromTo(elements, {
    opacity: 0,
  }, {
    opacity: 1,
    ease: "sine.inOut",
    duration: 0.5,
    stagger: {
      each: 0.1,
      ease: "sine.inOut",
    },
  });

  return tl;
}



export default forwardRef(({
  className = "",
  next = () => {},
  previous = () => {},
  showData = () => {},
  hideData = () => {},
}, ref) => {
  const [current, setCurrent] = useState(null);
  const [headlines, setHeadlines] = useState({
    intro: useRef(),
    rocket: useRef(),
    earth: useRef(),
    gears: useRef(),
    boat: useRef(),
    outro: useRef(),
  });

  const [subheadlines, setSubHeadlines] = useState({
    intro: useRef(),
    rocket: useRef(),
    earth: useRef(),
    gears: useRef(),
    boat: useRef(),
    outro: useRef(),
  });

  useImperativeHandle(ref, () => ({
    transition(target){
      let tl = gsap.timeline();
      if (current){
        tl.to(headlines[current].current.children, {opacity: 0, pointerEvents: "none", ease: "sine.inOut", duration: 0.25}, 0);
        tl.to(subheadlines[current].current.children, {opacity: 0, pointerEvents: "none", ease: "sine.inOut", duration: 0.25}, 0);
      }
      if (target){
        tl.add(spanFadein(headlines[target].current.children));
        tl.add(spanFadein(subheadlines[target].current.children));
      }
      setCurrent(target);
      return tl;
    },
  }));


  return <div className={css.sceneHeadline}>
    <div className={css.headline}>
      <div ref={headlines.intro}>
        <span className={`initialInvis ${css.red}`}>GETTR&apos;s&nbsp;</span>
        <span className="initialInvis">Growth&nbsp;</span>
        <span className="initialInvis">Story&nbsp;</span>
      </div>
      <div ref={headlines.rocket}>
        <span className="initialInvis">1&nbsp;</span>
        <span className="initialInvis">|&nbsp;</span>
        <span className="initialInvis">Stratospheric&nbsp;</span>
        <span className="initialInvis">Success</span>
      </div>
      <div ref={headlines.earth}>
        <span className="initialInvis">2&nbsp;</span>
        <span className="initialInvis">|&nbsp;</span>
        <span className="initialInvis">A&nbsp;</span>
        <span className="initialInvis">Growing&nbsp;</span>
        <span className="initialInvis">Global&nbsp;</span>
        <span className="initialInvis">Network</span>
      </div>
      <div ref={headlines.gears}>
        <span className="initialInvis">3&nbsp;</span>
        <span className="initialInvis">|&nbsp;</span>
        <span className="initialInvis">One&nbsp;</span>
        <span className="initialInvis">Platform&nbsp;</span>
        <span className="initialInvis">to&nbsp;</span>
        <span className="initialInvis">Rule&nbsp;</span>
        <span className="initialInvis">Them&nbsp;</span>
        <span className="initialInvis">All</span>
      </div>
      <div ref={headlines.boat}>
        <span className="initialInvis">4&nbsp;</span>
        <span className="initialInvis">|&nbsp;</span>
        <span className="initialInvis">Smooth&nbsp;</span>
        <span className="initialInvis">Sailing&nbsp;</span>
        <span className="initialInvis">Into&nbsp;</span>
        <span className="initialInvis">The&nbsp;</span>
        <span className="initialInvis">Future</span>
      </div>
      <div ref={headlines.outro}>
        <span className="initialInvis">5&nbsp;</span>
        <span className="initialInvis">|&nbsp;</span>
        <span className="initialInvis">What&nbsp;</span>
        <span className="initialInvis">Is&nbsp;</span>
        <span className="initialInvis">Gettr</span>
        <span className="initialInvis">?</span>
      </div>
    </div>
    <div className={`${css.subheadline}`}>
      <div ref={subheadlines.intro}>
        <span className="initialInvis">Arranging&nbsp;</span>
        <span className="initialInvis">the&nbsp;</span>
        <span className="initialInvis">pieces&nbsp;</span>
        <span className="initialInvis">of&nbsp;</span>
        <span className="initialInvis">GETTR&apos;s&nbsp;</span>
        <span className="initialInvis">success&nbsp;</span>
        <Button id="start" ghost className={`initialInvis ${css.button}`} onClick={next}>Reveal the Progress</Button>
      </div>
      <div className="initialInvis" ref={subheadlines.rocket}>
        <Button id="rocketBack" onClick={previous} icon={<ArrowLeftSvg/>} disabled/>
        <Button id="rocketData" icon={<ChartSvg/>} onClick={showData}/>
        <Button id="rocketNext" onClick={next} icon={<ArrowRightSvg/>}/>
      </div>
      <div className="initialInvis" ref={subheadlines.earth}>
        <Button id="earthBack" onClick={previous} icon={<ArrowLeftSvg/>}/>
        <Button id="earthData" icon={<ChartSvg/>} onClick={showData}/>
        <Button id="earthNext" onClick={next} icon={<ArrowRightSvg/>}/>
      </div>
      <div className="initialInvis" ref={subheadlines.gears}>
        <Button id="gearsBack" onClick={previous} icon={<ArrowLeftSvg/>}/>
        <Button id="gearsData" icon={<ChartSvg/>} onClick={showData}/>
        <Button id="gearsNext" onClick={next} icon={<ArrowRightSvg/>}/>
      </div>
      <div className="initialInvis" ref={subheadlines.boat}>
        <Button id="boatBack"onClick={previous} icon={<ArrowLeftSvg/>}/>
        <Button id="boatData"icon={<ChartSvg/>} onClick={showData}/>
        <Button id="boatNext"onClick={next} icon={<ArrowRightSvg/>}/>
      </div>
      <div className="initialInvis" ref={subheadlines.outro}>
        <Button id="outroBack" onClick={previous} icon={<ArrowLeftSvg/>}/>
        <Button id="outroData" icon={<ChartSvg/>} onClick={showData}/>
        <Button id="outroNext" onClick={next} icon={<ArrowRightSvg/>} disabled/>
      </div>
    </div>
  </div>;
});


