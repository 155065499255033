import React, { useEffect, forwardRef, useImperativeHandle, useRef, useState } from "react";
import css from "./index.module.scss";
import gsap from "@/scripts/gsap";
import Button from "@/components/button";
import ArrowRightSvg from "/public/icons/arrowRight.svg";
import ArrowLeftSvg from "/public/icons/arrowLeft.svg";
import { DrawSVGPlugin } from "@/scripts/gsap/DrawSVGPlugin";



const entries = [
  {
    headline: "GETTR Live (Studio)",
    text: "This isn't just livestreaming. This is life, streaming.",
  },
  {
    headline: "Closed Captions (GTok)",
    text: "Never get lost in translation.",
  },
  {
    headline: "Point Tipping",
    text: "Recognize your favorite creators.",
  },
  {
    headline: "Autocut (GTok)",
    text: "Make movies from images automagically.",
  },
  {
    headline: "Music Library (GTok)",
    text: "10,000+ tracks at your fingertips.",
  },
  {
    headline: "Referral Program",
    text: "Get rewarded when your friends join GETTR.",
  },
];


const timeline = [
  {
    headline: "June 2023",
    texts: [
      "GETTR Coin redemption to close-loop gift cards",
      "Chill Mode (fun/adorable content)",
      "Monetization with tutorials and promos",
    ],
  },
  {
    headline: "July 2023",
    texts: [
      "GETTR 2nd anniversary",
      "Co-Pilot (Generative AI)",
      "GTOK Autocut V1.0",
      "GTOK Music Library",
      "GETTR Point Tipping Anywhere",
      "GETTR New Home page (landing page)",
    ],
  },
  {
    headline: "August 2023",
    texts: [
      "User Referral Program",
      "GETTR Lite (Mobile App for Livestream)",
      "GETTR Live for Tablets",
      "GTOK AutoCut V2.0",
      "GETTR Loyalty Program V1.0",
      "More to come",
    ],
  },
];

export default forwardRef(({
  className = "",
  exit = () => {},
}, ref) => {
  const intro = useRef();
  const statistics = useRef();
  const root = useRef();
  const entriesRefs = useRef([]);
  const timelineRefs = useRef([]);

  function toStatistics(){
    let tl = gsap.timeline();
    tl.to(intro.current, {
      opacity: 0,
      pointerEvents: "none",
      duration: 0.5,
    }, 0);
    tl.set(statistics.current, {visibility: "visible"});
    tl.to(statistics.current, {
      opacity: 1,
      pointerEvents: "all",
      duration: 0.5,
    }, 0);

    for (let i = 0; i < timelineRefs.current.length; i++){
      let subTl = gsap.timeline();
      subTl.fromTo(timelineRefs.current[i].querySelector(`.${css.date}`), {
        opacity: 0,
      }, {
        opacity: 1,
        duration: 0.125,
        ease: "sine.inOut",
      }, 0);


      subTl.fromTo(timelineRefs.current[i].querySelector(`.${css.before}`), {
        scaleX: 0,
        transformOrigin: "center right",
      }, {
        scaleX: 1,
        duration: 0.125,
        ease: "sine.inOut",
      }, "<50%");
      subTl.fromTo(timelineRefs.current[i].querySelector(`.${css.after}`), {
        scaleX: 0,
        transformOrigin: "center left",
      }, {
        scaleX: 1,
        duration: 0.125,
        ease: "sine.inOut",
      }, "<");

      subTl.fromTo(timelineRefs.current[i].querySelectorAll(".piece"), {
        opacity: 0,
      }, {
        opacity: 1,
        duration: 0.125,
        ease: "sine.inOut",
        stagger: {
          each: 0.025,
          ease: "sine.inOut",
        },
      }, 0.025);
      tl.add(subTl, "<50%");
    }
    tl.fromTo(statistics.current.querySelectorAll(`.${css.buttons} .piece`), {
      opacity: 0,
    }, {
      opacity: 1,
      duration: 0.5,
      ease: "sine.inOut",
      stagger: {
        each: 0.05,
        ease: "sine.inOut",
      },
    }, "<50%");
    return tl;
  }


  function toIntro(){
    let tl = gsap.timeline();
    tl.to(statistics.current, {
      opacity: 0,
      pointerEvents: "none",
      duration: 0.5,
    }, 0);
    tl.set(intro.current, {opacity: 1, pointerEvents: "all"}, 0);
    tl.fromTo(intro.current.querySelectorAll(".piece"), {
      opacity: 0,
    }, {
      opacity: 1,
      duration: 0.5,
      ease: "sine.inOut",
      stagger: {
        each: 0.01,
        ease: "sine.inOut",
      },
    }, "<75%");


    for (let i = 0; i < entriesRefs.current.length; i++){
      let subTl = gsap.timeline();
      subTl.fromTo(entriesRefs.current[i].querySelector(`.${css.date}`), {
        opacity: 0,
      }, {
        opacity: 1,
        duration: 0.125,
        ease: "sine.inOut",
      }, 0);


      subTl.fromTo(entriesRefs.current[i].querySelector(`.${css.before}`), {
        scaleX: 0,
        transformOrigin: "center right",
      }, {
        scaleX: 1,
        duration: 0.125,
        ease: "sine.inOut",
      }, "<50%");
      subTl.fromTo(entriesRefs.current[i].querySelector(`.${css.after}`), {
        scaleX: 0,
        transformOrigin: "center left",
      }, {
        scaleX: 1,
        duration: 0.125,
        ease: "sine.inOut",
      }, "<");

      subTl.fromTo(entriesRefs.current[i].querySelectorAll(".piece"), {
        opacity: 0,
      }, {
        opacity: 1,
        duration: 0.125,
        ease: "sine.inOut",
      }, 0.025);
      tl.add(subTl, "<0.05");
    }
    return tl;
  }

  function handleExit(){
    gsap.to([statistics.current, intro.current, root.current], {
      opacity: 0,
      pointerEvents: "none",
      duration: 0.5,
      ease: "sine.inOut",
    });
    exit();
  }

  useEffect(() => {
    gsap.registerPlugin(DrawSVGPlugin);
  }, []);


  useImperativeHandle(ref, () => ({
    show(target){
      let tl = gsap.timeline();
      tl.set(root.current, {visibility: "visible", opacity: 0, pointerEvents: "none"});
      tl.set(intro.current, {visibility: "visible", opacity: 1, pointerEvents: "all"});
      tl.set(statistics.current, {visibility: "visible", opacity: 0, pointerEvents: "none"});
      tl.to(root.current, {
        opacity: 1,
        pointerEvents: "all",
        duration: 0.5,
        ease: "sine.inOut",
      }, 0);
      tl.add(toIntro());
      return tl;
    },
  }));


  return <div className={`initialInvis ${className} ${css.rocketModal}`} ref={root}>
    <div className={css.inner}>
      <div className={css.intro} ref={intro}>
        <div className={css.headline}>
          <span className={`piece ${css.red}`}>Technology Innovation&nbsp;</span>
          <span className="piece">is Central to&nbsp;</span>
          <span className="piece">GETTR&apos;s Growth&nbsp;</span>
        </div>
        <div className={css.list}>
          {entries.map((entry, idx) => <div key={entry.headline} className={css.entry} ref={(element) => (entriesRefs.current[idx] = element)}>
            <div>
              <span className={css.before}></span>
              <span className={css.date}>{entry.headline}</span>
              <span className={css.after}></span>
            </div>
            <div className="piece">{entry.text}</div>
          </div>)}
        </div>


        <div className={css.buttons}>
          <Button id="boatStep1Exit" onClick={handleExit} className={`piece ${css.button}`} ghost><ArrowLeftSvg/> <span>Exit</span></Button>
          <Button id="boatStep1Next" onClick={toStatistics} className={`piece ${css.button}`}><span>Next</span> <ArrowRightSvg/></Button>
        </div>
      </div>

      <div className={`initialInvis ${css.statistics}`} ref={statistics}>
        <div className={css.headline}>Encouraging Engagement and Inclusivity With</div>
        <div className={css.list}>
          {timeline.map((entry, idx) => <div key={entry.headline} className={css.entry} ref={(element) => (timelineRefs.current[idx] = element)}>
            <div>
              <span className={css.before}></span>
              <span className={css.date}>{entry.headline}</span>
              <span className={css.after}></span>
            </div>
            {entry.texts.map((text) => <div className="piece" key={text}>{text}</div>)}
          </div>)}
        </div>
        <div className={css.buttons}>
          <Button id="boatStep2Back" onClick={toIntro} className={`piece ${css.button}`} ghost><ArrowLeftSvg/> <span>Back</span></Button>
          <Button id="boatStep2Next" onClick={handleExit} className={`piece ${css.button}`}><span>Next</span><ArrowRightSvg/></Button>
        </div>
      </div>
    </div>
  </div>;
});


