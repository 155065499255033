import React, { useEffect, forwardRef, useImperativeHandle, useRef, useState } from "react";
import css from "./index.module.scss";
import CircleShards from "/public/shards/circle.svg";
import GettrShards from "/public/shards/gettr.svg";
import RocketShards from "/public/shards/rocket.svg";
import EarthShards from "/public/shards/earth.svg";
import BaseShards from "/public/shards/base.svg";
import BoatShards from "/public/shards/boat.svg";
import GearsShards from "/public/shards/gears.svg";
import gsap from "@/scripts/gsap";
import {shuffle} from "@/scripts/utils";



function spanFadein(elements){
  let tl = gsap.timeline();
  tl.set(elements, {visibility: "visible"});
  tl.fromTo(elements, {
    opacity: 0,
  }, {
    opacity: 1,
    ease: "sine.inOut",
    duration: 0.5,
    stagger: {
      each: 0.1,
      ease: "sine.inOut",
    },
  });
  tl.to(elements, {opacity: 0, ease: "sine.inOut", duration: 0.25}, ">0.5");

  return tl;
}



export default forwardRef(({
  className = "",
}, ref) => {
  const [lines, setLines] = useState({
    0: useRef(),
    1: useRef(),
    2: useRef(),
    3: useRef(),
    4: useRef(),
    5: useRef(),
  });


  useImperativeHandle(ref, () => ({
    transition(){
      let tl = gsap.timeline();
      tl.add(spanFadein(lines[0].current.children));
      tl.add(spanFadein(lines[1].current.children));
      tl.add(spanFadein(lines[2].current.children));
      tl.add(spanFadein(lines[3].current.children));
      tl.add(spanFadein(lines[4].current.children));
      tl.add(spanFadein(lines[5].current.children));

      return tl;
    },
  }));


  return <div className={`${css.poem} ${className}`}>
    <div ref={lines[0]}>
      <span className="initialInvis">Hello&nbsp;</span>
      <span className="initialInvis">world,&nbsp;</span>
      <span className="initialInvis">we&apos;re&nbsp;</span>
      <span className={`initialInvis ${css.red}`}>GETTR,</span>
    </div>
    <div ref={lines[1]} >
      <span className="initialInvis">Social&nbsp;</span>
      <span className="initialInvis">media,&nbsp;</span>
      <span className="initialInvis">but&nbsp;</span>
      <span className={`initialInvis ${css.red}`}>BETTR.</span>
    </div>
    <div ref={lines[2]}>
      <span className="initialInvis">A&nbsp;</span>
      <span className={`initialInvis ${css.red}`}>boundless&nbsp;</span>
      <span className={`initialInvis ${css.red}`}>realm&nbsp;</span>
      <span className="initialInvis">where&nbsp;</span>
      <span className="initialInvis">thoughts&nbsp;</span>
      <span className="initialInvis">take&nbsp;</span>
      <span className="initialInvis">flight,&nbsp;</span>
    </div>
    <div ref={lines[3]}>
      <span className="initialInvis">Free&nbsp;</span>
      <span className="initialInvis">speech&nbsp;</span>
      <span className="initialInvis">ignites,&nbsp;</span>
      <span className="initialInvis">a&nbsp;</span>
      <span className={`initialInvis ${css.red}`}>beacon&nbsp;</span>
      <span className={`initialInvis ${css.red}`}>burning&nbsp;</span>
      <span className={`initialInvis ${css.red}`}>bright.&nbsp;</span>
    </div>
    <div ref={lines[4]}>
      <span className="initialInvis">If&nbsp;</span>
      <span className={`initialInvis ${css.red}`}>fame&nbsp;</span>
      <span className={`initialInvis ${css.red}`}>and&nbsp;</span>
      <span className={`initialInvis ${css.red}`}>fortune&nbsp;</span>
      <span className="initialInvis">is&nbsp;</span>
      <span className="initialInvis">your&nbsp;</span>
      <span className="initialInvis">aim,&nbsp;</span>
    </div>
    <div ref={lines[5]}>
      <span className="initialInvis">We&nbsp;</span>
      <span className="initialInvis">welcome&nbsp;</span>
      <span className="initialInvis">you&nbsp;</span>
      <span className="initialInvis">with&nbsp;</span>
      <span className={`initialInvis ${css.red}`}>great&nbsp;</span>
      <span className={`initialInvis ${css.red}`}>acclaim.&nbsp;</span>
    </div>
  </div>;
});



/*

  function changeFlicker(thisShards){
    if (flickerTl){
      flickerTl.kill();
    }

    let sortedShards = Array.from(thisShards).sort((a, b) => {
      let aRect = a.getBoundingClientRect();
      let bRect = b.getBoundingClientRect();
      return aRect.top - bRect.top;
    });

    for (let i = 0; i < sortedShards.length; i++){
      let shard = sortedShards[i];
      let newIndex = Math.min(sortedShards.length, Math.max(0, i + Math.floor((Math.random() * 20) - 10)));
      sortedShards.splice(i, 1);
      sortedShards.splice(newIndex, 0, shard);
    }

    setflickerTl(gsap.fromTo(sortedShards, {
      filter: "brightness(100%) invert(0%)",
    }, {
      filter: "brightness(90%) invert(10%)",
      ease: CustomEase.create("custom", "M0,0,C0.2,0,0.3,1,0.5,1,0.706,1,0.8,0,1,0"),
      duration: 0.4,
      stagger: {
        each: 0.005,
        ease: "sine.inOut",
        repeat: -1,
        repeatDelay: 3,
      },
      delay: 3,
    }));
  }
  */
